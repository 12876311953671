<template>
  <v-row class="mx-0">
    <v-col cols="12" v-if="$store.state.accounts.retrieveLoader">
      <v-boilerplate type="table-heading, list-item-two-line" />
      <v-skeleton-loader class="mt-5" transition="fade-transition" type="table" />
    </v-col>
    <v-col cols="12" v-else>
      <v-card flat>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">
            <v-row align="center" justify="start" no-gutters>
              <router-link :to="{name: 'AccountSettingsWebhooksListCreate', params: $route.params}" class="subtitle-1 routerLink font-weight-medium">Webhooks</router-link>
              <v-icon class="mx-1 pt-1" color="secondary">mdi-chevron-right</v-icon>
              <span class="subtitle-1 font-weight-medium d-inline-block text-truncate" :style="$vuetify.breakpoint.width < 1200 ? 'max-width: 200px;' : 'max-width: 500px;'">{{instance.url}}</span>
            </v-row>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn class="mr-1" @click="toggleStatus" outlined retain-focus-on-click color="secondary">
                <template v-if="instance.status === 'enabled'"><v-icon left small>mdi-cancel</v-icon>Deshabilitar</template>
                <template v-else>Habilitar</template>
              </v-btn>
              <v-btn class="mr-1" @click="destroyWebhook" outlined retain-focus-on-click color="secondary"><v-icon left small>mdi-close</v-icon>Eliminar</v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 py-0 background">
          <v-row class="mx-0">
            <v-col cols="2">
              <span class="d-block mb-1">Tipo de webhook</span>
              <v-chip color="defaultGrey" small text-color="secondary">Cuenta</v-chip>
            </v-col>
            <v-divider class="my-2" vertical />
            <v-col cols="2" class="ml-2">
              <span class="d-block mb-1">Estado</span>
              <v-chip v-if="instance.status === 'enabled'" color="lightBlue" small text-color="primary">Habilitado</v-chip>
              <v-chip v-else-if="instance.status === 'disabled'" color="defaultGrey" small text-color="secondary">Deshabilitado</v-chip>
            </v-col>
            <v-divider class="my-2" vertical />
            <v-col cols="2" class="ml-2">
              <span class="d-block mb-1">Disponible desde</span>
              <div class="font-weight-medium mt-1">{{instance.created | date}}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="px-0 my-5" flat>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Información general</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn class="mr-1" @click="modalUpdate=true" outlined retain-focus-on-click color="secondary">Actualizar datos</v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 py-4">
          <v-row>
            <v-col cols="10">
              <v-list class="v-list-form transparent">
                <v-list-item>
                  <v-list-item-title>URL</v-list-item-title>
                  <v-list-item-subtitle>{{instance.url}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Descripción</v-list-item-title>
                  <v-list-item-subtitle>{{instance.description || '--'}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Eventos</v-list-item-title>
                  <v-list-item-subtitle>{{instance.events[0]}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="instance.events.length > 1">
                  <v-list-item-title></v-list-item-title>
                  <v-list-item-subtitle>
                    <template v-for="(e, i) in instance.events">
                      <span class="d-block mb-2" v-if="i > 0" :key="i">{{e}}</span>
                    </template>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="px-0 my-5" flat>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Secreto</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 py-0">
          <v-row class="mx-0" align="center">
            <v-col cols="8">
              <v-text-field class="icon-field" v-model="instance.secret" @click:append="showSecret = !showSecret" :append-icon="showSecret ? 'mdi-eye' : 'mdi-eye-off'" :type="showSecret ? 'value' : 'password'" readonly outlined hide-details required single-line dense />
            </v-col>
            <v-col class="text-right" cols="4">
              <v-btn class="mr-1" @click="secretRenew" outlined retain-focus-on-click color="secondary">Renovar secreto</v-btn>
              <v-btn @click="toClipboard(instance.secret)" outlined retain-focus-on-click color="secondary"><v-icon x-small left>mdi-content-copy</v-icon> Copiar secreto</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="px-0 my-5" flat>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Conversaciones del webhook</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0 py-0">
          <v-row no-gutters>
            <v-col cols="12">
              <v-skeleton-loader v-if="$store.state.accounts.retrieveLoader" transition="fade-transition" type="table"/>
              <template v-else>
                <template v-if="conversationsList.length">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Evento</th>
                          <th>Código de estado de HTTP</th>
                          <th>Fecha de ejecución</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="c in conversationsList" :key="c.id">
                          <td>
                            <span class="text--secondary font-weight-medium">{{c.event}}</span>
                          </td>
                          <td>{{c.status_code}}</td>
                          <td>{{c.created | dateTime}}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider />
                  <v-row class="mx-0" align="center">
                    <v-col cols="12">
                      <span class="body-2 text--secondary">{{conversationsCount}} registros</span>
                    </v-col>
                  </v-row>
                </template>
                <v-col class="text-center py-5" v-else>
                  <v-icon class="d-block" color="secondary" size="48">mdi-message-bulleted-off</v-icon>
                  <span class="d-block subtitle-1">Sin conversaciones recientes con el webhook</span>
                </v-col>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- dialog update webhook -->
    <v-dialog v-model="modalUpdate" width="800" persistent no-click-animation overlay-color="overlay">
      <v-card flat>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Editar webhook</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn icon retain-focus-on-click :ripple="false" small @click="modalUpdate=false"><v-icon small>mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="v-list-form py-6 transparent">
            <v-list-item class="py-2">
              <v-list-item-title>URL de conexión</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.editable.url.$model" :error="$v.editable.url.$error" placeholder="Ej: https://webhooks.myproduct.com/axteroid/" outlined required single-line dense hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-2">
              <v-list-item-title>Descripción</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.editable.description.$model" :error="$v.editable.description.$error" outlined required single-line dense hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list class="v-list-form py-6 transparent">
            <v-list-item class="py-2">
              <v-list-item-title>
                <span class="d-block">Eventos</span>
                <v-btn class="my-1" :href="`${$DOCS_BASE}${guides[0]}`" target="_blank" text color="primary" :ripple="false" small>Aprende más sobre los eventos</v-btn>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-row class="mx-0">
                  <v-col cols="8">
                    <v-select v-model="$v.editable.events.$model" :error="$v.editable.events.$error" :items="eventsList" label="Seleccionar eventos" item-value="name" item-text="name" outlined solo flat dense multiple hide-details>
                      <template v-slot:selection="{ index }">
                        <span class="mx-1" v-if="index === 0">Seleccionar eventos</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="text-right" cols="4">
                    <v-btn class="my-1" @click="editable.events = []" text color="primary" :ripple="false">Limpiar</v-btn>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title></v-list-item-title>
              <v-list-item-subtitle>
                <v-card class="mx-1" outlined height="189px" style="overflow: scroll;">
                  <v-card-text class="pa-0">
                    <v-list class="v-list-form py-0" dense v-if="editable.events.length">
                      <template v-for="(d, i) in editable.events">
                        <v-list-item class="py-0 my-0" :key="`${i}-item`">
                          <v-list-item-title>{{d}}</v-list-item-title>
                          <v-list-item-action class="ma-0 text-right">
                            <v-btn @click="editable.events.splice(i, 1)" text color="primary" :ripple="false" small><v-icon right small>mdi-close</v-icon></v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="`${i}-divider`" />
                      </template>
                    </v-list>
                    <v-col class="text-center py-14 px-6" v-else>
                      <span class="d-block text--secondary subtitle-1 font-weight-medium">Sin eventos seleccionados.</span>
                      <span class="d-block body-2">Usa el menú desplegable de arriba para buscar y seleccionar los eventos.</span>
                    </v-col>
                  </v-card-text>
                </v-card>
                <span class="d-block mx-1 py-1 text--secondary body-2">{{editable.events.length}} eventos</span>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="modalUpdate=false" outlined>Cancelar</v-btn>
          <v-btn @click="updateWebhook" :loading="$store.state.accounts.createLoader" color="primary">Actualizar webhook</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog update webhook -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, maxLength, url } from 'vuelidate/lib/validators'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  inject: ['theme'],
  components: {
    VTableHeaders,
    VBoilerplate: {
      functional: true,
      render (h, { data, props, children }) {
        return h('v-skeleton-loader', {
          ...data,
          props: {
            boilerplate: true,
            elevation: 2,
            ...props
          }
        }, children)
      }
    }
  },
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    editable: {
      description: '',
      events: [],
      url: ''
    },
    guides: [
      '/guide/desarrolladores/webhooks#eventos'
    ],
    modalUpdate: false,
    showSecret: false,
    secretRenewLoader: false
  }),
  computed: mapState({
    instance: state => state.accounts.webhooksDetail,
    conversationsCount: state => state.accounts.conversationsCount,
    conversationsList: state => state.accounts.conversationsList,
    eventsList: state => state.accounts.eventsList
  }),
  created () {
    this.$store.dispatch('accounts/RETRIEVE', {
      resource: 'webhooks',
      id: this.$route.params.id
    })
    .then(() => {
      this.editable = Object.assign({}, {
        description: this.instance.description,
        events: this.instance.events,
        url: this.instance.url
      })
      this.$store.dispatch('accounts/LIST', {
        resource: 'conversations',
        query: {
          webhook: this.instance.id
        }
      })
    })
    this.$store.dispatch('accounts/LIST', {
      resource: 'events',
      query: {
        page_size: 100
      }
    })
  },
  methods: {
    updateWebhook () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
      this.$store.dispatch('accounts/UPDATE', {
        resource: 'webhooks',
        id: this.instance.id,
        payload: this.editable
      })
      .then(() => {
        this.modalUpdate = false
        this.$dialog.message.info('Webhook actualizado.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    destroyWebhook () {
      this.$dialog.warning({
        title: 'Eliminar webhook',
        text: `
          <span class="d-block mb-4">Al eliminar el webhook, no seguirá recibiendo notificaciones en la URL.</span>
          <span class="d-block mt-4 font-weight-medium">¿Estás seguro de continuar?</span>
        `,
        actions: [
          {
            color: 'red',
            text: 'Eliminar webhook',
            handle: () => {
              this.$store.dispatch('accounts/DESTROY', {
                resource: 'webhooks',
                id: this.instance.id
              })
              .then(() => {
                this.$dialog.message.info('Webhook eliminado.')
                this.$router.push({
                  params: this.$route.params,
                  name: 'AccountSettingsWebhooksListCreate'
                })
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
              })
            }
          }
        ]
      })
    },
    toggleStatus () {
      const disableText = `
        <span class="d-block mb-4">Al deshabilitar el webhook, no seguirá recibiendo notificaciones hasta que nuevamente sea habilitado.</span>
        <span class="d-block my-4 font-weight-medium">¿Estás seguro de continuar?</span>
      `
      const enableText = `
        <span class="d-block mb-4">Al habilitar el webhook, volvera a recibir notificaciones hasta que sea deshabilitado o eliminado.</span>
        <span class="d-block mt-4 font-weight-medium">¿Estás seguro de continuar?</span>
      `
      this.$dialog.warning({
        title: (this.instance.status === 'enabled' ? 'Deshablitar' : 'Habilitar') + ' webhook',
        text: this.instance.status === 'enabled' ? disableText : enableText,
        actions: [
          {
            color: 'primary',
            text: (this.instance.status === 'enabled' ? 'Deshablitar' : 'Habilitar') + ' webhook',
            handle: () => {
              this.$store.dispatch('accounts/UPDATE', {
                resource: 'webhooks',
                id: this.instance.id,
                payload: {
                  status: this.instance.status === 'enabled' ? 'disabled' : 'enabled'
                }
              })
              .then(() => {
                this.$dialog.message.info('Webhook actualizado.')
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
              })
            }
          }
        ]
      })
    },
    secretRenew () {
      this.$dialog.warning({
        title: 'Renovar secreto',
        text: `
          <span class="d-block mb-4">Al renovar el secreto del webhook asegúrate de actualizar el destino con el nuevo secreto.</span>
          <span class="d-block mt-4 font-weight-medium">¿Estás seguro de continuar?</span>
        `,
        actions: [
          {
            color: 'primary',
            text: 'Renovar secreto',
            handle: () => {
              this.$store.dispatch('accounts/RETRIEVE', {
                resource: 'webhooks',
                detail: 'secret_renew',
                id: this.instance.id,
                loader: false
              })
              .then(() => {
                this.$dialog.message.info('Secreto del webhook renovado.')
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
              })
            }
          }
        ]
      })
    }
  },
  validations: {
    editable: {
      description: {
        maxLength: maxLength(512)
      },
      events: {
        required
      },
      url: {
        required,
        url
      }
    }
  }
}
</script>